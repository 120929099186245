<template>
  <el-container style="height: 100%;" class="wrap-info">
    <el-header style="text-align: right; font-size: 12px" v-if="taskId">
      <el-row>
        <el-alert
            title="当前处于只读状态"
            type="warning"
            :closable="false"
            center
            v-if="isReadOnly"
        />
        <el-col style="background: #ffffff" v-else>
          <el-button-group v-if="!isPending">
            <el-button
                @click="clickBtn(item.functionName)"
                v-for="(item, index) in btnList"
                :type="item.label=='发送'?'primary':'default'"
                :key="index"
                :icon="item.icon"
            >{{ item.label }}
            </el-button
            >
          </el-button-group>
          <el-tag type="info" style="margin: 4px 10px 0 0; float: right;color: red">{{
              currentStepName
            }}
          </el-tag>
          <el-button-group v-if="!isPending" style="float: right;margin-right: 5px">
              <el-button @click="clickBtn('pendingTask();')">缓办</el-button>
              <el-button @click="clickBtn('taskRedirect();')">转交</el-button>
              <el-button @click="clickBtn('taskCancel();')">作废</el-button>
<!--              <el-button @click="clickBtn('taskClose();')">关闭</el-button>-->
          </el-button-group>
          <el-button-group v-else style="float: right;margin-right: 5px">
            <el-button @click="clickBtn('solutionPendTask();')">解除缓办</el-button>
<!--            <el-button @click="clickBtn('taskClose();')">关闭</el-button>-->
          </el-button-group>
        </el-col>
      </el-row>
    </el-header>
    <el-container :style=" taskId ? 'height: 200px;' : ''">
      <el-aside width="auto" :style="{width: width+'px'}">
        <el-scrollbar>
          <div class="wrap-mp">
            <div class="wp">
              <span>表单列表</span>
            </div>
            <el-menu
                :default-active="actionIndex"
                class="el-menu-vertical-demo"
            >
              <el-menu-item
                  :index="item.Id"
                  @click="getUrl(item.path,item)"
                  v-for="item in formList"
                  :key="item.Id"
              >
                <el-icon>
                  <Briefcase/>
                </el-icon>
                <span>{{ item.label }}</span>
              </el-menu-item>
            </el-menu>
          </div>
          <div>
            <fileList ref="fileList" :width="width"></fileList>
          </div>
        </el-scrollbar>
      </el-aside>
      <XHandle class="myxhandle" @widthChange="widthChange" title="可拉伸"/>
      <el-main style="position: relative;">
        <router-view v-slot="{ Component }">
            <component :is="Component"/>
        </router-view>
      </el-main>
    </el-container>
  </el-container>
  <el-dialog v-model="dialogFormVisible" width="30%">
    <el-form :model="form" ref="formSub">
      <el-form-item label="下一环节" prop="xyhj" :label-width="formLabelWidth">
        <el-select
            v-model="form.xyhj"
            placeholder="请选择"
            @change="handleOptions"
            style="width: 100%"
        >
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.name"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="指定人员" v-show="!finishStatus" :label-width="formLabelWidth">
        <InputTree
            :isExpand="false"
            :bm="form.zdry"
            :stepId="nextStepId"
            @getInput="getInput"
            ref="UserTree"
        ></InputTree>
      </el-form-item>
      <el-form-item
          label="处理意见"
          prop="clyj"
          :label-width="formLabelWidth"
          style="margin-bottom: 0"
      >
        <el-input v-model="form.clyj" type="textarea" :rows="3"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmSub('formSub')"
        >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="taskRedirectdialogVisible" width="310px">
    <el-form ref="formzjr">
      <el-form-item label="转交人" prop="taskzjr">
        <el-select
            v-model="taskzjr"
            placeholder="请选择"
            @change="taskzjrOption"
        >
          <el-option
              v-for="item in taskzjroptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="taskRedirectdialogVisible = false">取消</el-button>
        <el-button type="primary" @click="taskRedirect()">确认</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="DelaydialogVisible" width="30%" title="确认将此项目设置为缓办项目?">
    <el-form>
      <el-form-item label="缓办原因" :label-width="formLabelWidth">
        <el-input v-model="DelayRemark" autocomplete="off" type="textarea" :rows="3"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="DelaydialogVisible = false">取消</el-button>
        <el-button type="primary" @click="TaskDelayRemark()">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="CanceldialogVisible" width="30%" title="确认将此项目作废？">
    <el-form>
      <el-form-item label="作废原因" :label-width="formLabelWidth">
        <el-input v-model.trim="CancelRemark" autocomplete="off" type="textarea" :rows="3"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="CanceldialogVisible = false">取消</el-button>
        <el-button type="primary" @click="TaskCancelRemark()">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {Briefcase} from '@element-plus/icons'
import {mapState} from 'vuex'
// import InfoList from './info.vue'
import fileList from './file.vue'
import {ElMessage} from 'element-plus'
import InputTree from '@/components/Input/InputTree.vue'
import XHandle from '../../components/XHandle'

export default {
  data() {
    return {
      finishStatus:false,
      cache: [],
      formList: [],
      btnList: [],
      fid: '',
      instanceId: '', //默认查询的值
      taskId: '',
      apiUrl: this.$appConfig.apiUrls.flowApi,
      routePath: '',
      actionIndex: '',
      //actionCompnentName: '',
      currentStepName: '',
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        xyhj: '',
        zdry: '',
        uid: '',
        clyj: '',
      },
      nextStepId: '0',
      options: [{name: '自动处理', value: ''}],
      formLabelWidth: '100px',
      checkBtn: true,
      selectStep: '',
      isReadOnly: false,
      isPending: false,
      queryData: [],
      taskRedirectdialogVisible: false,
      taskzjr: '',
      thisstep: '',
      taskzjroptions: [],
      DelaydialogVisible: false,
      CanceldialogVisible: false,
      DelayRemark: '',
      CancelRemark: '',
      width: 250,
    }
  },
  computed: {
    ...mapState(['routeTags'])
  },
  watch: {
    taskId() {
      console.log(this.taskId)
      //this.loadForms()
    },
  },
  beforeRouteUpdate(to) {
    if (to.path == '/buslist/flowinfo') {
      if (to.query.row) {
        let r = JSON.parse(to.query.row)
        for (let i = 0; i < this.queryData.length; i++) {
          if (r.Id == this.queryData[i].instanceId) {
            this.fid = this.queryData[i].fid
            this.instanceId = this.queryData[i].instanceId
            this.taskId = this.queryData[i].taskId
          }
        }
        this.actionIndex = ''
        this.loadForms()
      }
    } else {
      this.loadForms()
    }
    this.cancelOperate()
  },
  mounted() {
    this.form.xyhj = this.options[0].value
    if (!this.$route.params.row) {
      return
    }
    console.log(this.$route.meta)
    const rowData = JSON.parse(this.$route.params.row)
    if (rowData.SBLX) {
      this.fid = rowData.SLBX
    }
    if (rowData.Id) {
      this.instanceId = rowData.Id
    }
    if (rowData.taskid) {
      this.taskId = rowData.taskid
    }
  },
  methods: {
    cancelOperate() {
      this.form = {
        xyhj: '',
        zdry: '',
        uid: '',
        clyj: '',
      },
          this.nextStepId = '0',
          this.dialogFormVisible = false
    },
    // 监测宽度
    widthChange(movement) {
      this.width -= movement;
      if (this.width < 250) {
        this.width = 250;
      }
      if (this.width > 600) {
        this.width = 600;
      }
    },
    showAlert() {
      if (this.isReadOnly) {
        ElMessage({
          type: 'error',
          message: '当前处于只读状态',
        })
      }
    },
    handleOptions(val) {
      this.selectStep = val
      this.form.zdry = ""
      this.form.uid = ""
      if (!val) {
        if (this.options.length > 1) {
          this.nextStepId = this.options[1].value
        }
      } else {
        this.nextStepId = val
      }
      this.$refs.UserTree.getOrganizeTree(this.nextStepId)
    },
    confirmSub(formName) {
      if (this.form.uid == ''&&this.form.xyhj.length > 1) {
        ElMessage({
          type: 'info',
          message: '请选择指定人员',
        })
      } else {
        if (this.checkBtn) {
          console.log('发送', this.form)
          this.taskSend()
        } else {
          console.log('退回', this.form)
          this.taskBack()
        }
        if (this.form.clyj !== '')
          this.dialogFormVisible = false
        this.$refs[formName].resetFields()
      }
    },
    taskzjrOption(val) {
      this.taskzjr = val;
    },
    getUrl(type, item) {
      this.actionIndex = item.Id
      const currentFrom = this.formList.find((from) => from.path === type)
      const FormKey = this.formList.findIndex((from) => from.path === type)
      if (currentFrom) {
        let query = {
          fid: this.fid,
          instanceId: this.instanceId,
          taskId: this.taskId,
        }

        if (
            this.formList[FormKey].parameters != null &&
            this.formList[FormKey].parameters != undefined
        ) {
          let arr = this.formList[FormKey].parameters.split('=')
          query[arr[0]] = arr[1]
        } else {
          console.log('')
        }
        if (currentFrom.Parameters) {
          const parameters = currentFrom.Parameters.split('&')
          for (let i = 0; i < parameters.length; i++) {
            const item = parameters[i]
            const q = item.split('=')
            if (q.length === 2) {
              query[q[0]] = q[1]
            }
          }
        }
        let obj = {}
        obj.path = this.routePath + '/' + currentFrom.path.trim()
        obj.query = query
        // console.log(query)
        obj.index = this.actionIndex
        window.sessionStorage.setItem(this.instanceId, JSON.stringify(obj))
        // let res = []
        // let lastIndex = obj.path.lastIndexOf('/')
        // res.push(obj.path.substring(lastIndex+1))
        // console.log(res)
        // console.log('跳过去了')
        // this.$store.commit('getRouteTags',res)
        this.$router.push({
          path: this.routePath + '/' + currentFrom.path.trim(),
          query: query,
        })
      }
    },
    // getCompnent(actionCompnentName) {
    //   this.actionCompnentName = actionCompnentName
    // },
    clickBtn(functionName) {
      console.log(functionName)
      const me = this
      switch (functionName) {
        case 'taskSend();':
          this.form.xyhj = this.options[0].value
          this.checkBtn = true
          this.dialogFormVisible = true
          this.form.clyj = '同意'
          this.getSteps('GetAfterSteps')
          break
        case 'taskBack();':
          this.checkBtn = false
          this.form.clyj = '退回'
          this.dialogFormVisible = true
          this.getSteps('GetBeforeSteps')
          break
        case 'taskClose();':
          for (let i = 0; i < me.$router.routeTags.length; i++) {
            if (me.$route.query.instanceId === me.$router.routeTags[i].meta.Id) {
              me.$router.handleClose(me.$router.routeTags[i])
            }
          }
          break
        case 'pendingTask();':
          me.DelaydialogVisible = true
          me.DelayRemark = ''
          break
        case 'solutionPendTask();':
          me.DelTaskDelay()
          break
        case 'taskRedirect();':
          me.taskRedirectdialogVisible = true
          me.taskzjr = ''
          break
        case 'taskCancel();':
          me.CanceldialogVisible = true
          me.CancelRemark = ''
          break
        case 'taskReflash();':
        default:
          this.reflash()
          break
      }
    },
    async getSteps(afterOrBefore) {
      let me = this
      await this.$ajax
          .post(
              this.apiUrl +
              '/api/WorkFlowStep/' +
              afterOrBefore +
              '?taskId=' +
              this.taskId
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              // console.log('得到的步骤数据：',response.data.Data);\
              me.finishStatus = false
              let op = null
              if(response.data.Data?.length > 0){
                 op = [{name: '自动处理', value: ''}]
              }else {
                 op = [{name: '办结', value: ''}]
                me.finishStatus = true
              }
              me.options = [...op, ...response.data.Data]
              if (response.data.Data.length > 0) {
                me.nextStepId = response.data.Data[0].value
              }
            } else {
              ElMessage({
                type: 'error',
                message: response.data.ErrorMessage,
              })
            }
          })
          .catch(function (error) {
            console.log(error)
            ElMessage({
              type: 'error',
              message: error,
            })
          })
      // console.log('获取的步骤：',me.options);
    },
    async loadForms() {
      let me = this
      await this.$ajax
          .post(
              this.apiUrl +
              '/api/WorkFlowStep/GetCurrentStepByInstanceId?instanceId=' +
              this.instanceId
          )
          .then(function (response) {
            if (response) {
              if (response.data.Data) {
                me.thisstep = response.data.Data.Id
                me.getzjr()
                me.currentStepName = response.data.Data.Name
                me.$store.state.currentStepName = response.data.Data.Name
              }
              if (response.data.Data && response.data.Data.StepForms) {
                me.formList = []
                for (let i = 0; i < response.data.Data.StepForms.length; i++) {
                  const form = response.data.Data.StepForms[i]
                  if (!form) {
                    continue
                  }
                  me.formList.push({
                    Id: i.toString(),
                    label: form.Title,
                    path: form.Address,
                    Parameters: form.Parameters,
                  })
                }
                if (me.formList.length > 0) {
                  if (!me.actionIndex) {
                    me.actionIndex = me.formList[0].Id
                  }
                  const currentFrom = me.formList.find(
                      (from) => from.Id === me.actionIndex
                  )
                  if (currentFrom) {
                    let query = {
                      fid: me.fid,
                      instanceId: me.instanceId,
                      taskId: me.taskId ? me.taskId : "",
                    }
                    if (currentFrom.Parameters) {
                      const parameters = currentFrom.Parameters.split('&')
                      for (let i = 0; i < parameters.length; i++) {
                        const item = parameters[i]
                        const q = item.split('=')
                        if (q.length === 2) {
                          query[q[0]] = q[1]
                        }
                      }
                    }
                    me.queryData.push(query)
                    for (let i = 0; i < me.queryData.length; i++) {
                      for (let j = i + 1; j < me.queryData.length; j++) {
                        if (
                            me.queryData[i].instanceId ===
                            me.queryData[j].instanceId
                        ) {
                          me.queryData.splice(j, 1)
                          j--
                        }
                      }
                    }
                    let obj = JSON.parse(window.sessionStorage.getItem(me.instanceId))

                    // for(let i=0;i<this.routeTags.length;i++){
                    //   // res.push(this.routeTags[i].href.slice(1))
                    //   let lastIndex = this.routeTags[i].href.lastIndexOf('/')
                    //   let index = this.routeTags[i].href.indexOf('?')
                    //   if(index != -1){
                    //     res.push(this.routeTags[i].href.substring(lastIndex+1,index))
                    //   }else {
                    //     res.push(this.routeTags[i].href.substring(lastIndex+1))
                    //   }
                    //   // console.log(this.routeTags)
                    //   // console.log(index,lastIndex,'345')
                    // }


                    // console.log(obj)
                    if (obj) {
                      let lastIndex = obj.path.lastIndexOf('/')
                      let paths = obj.path.substring(lastIndex + 1)
                      let res = me.$store.state.routeTags
                      let index = res.findIndex((item) => {
                        return item == paths
                      })
                      // console.log(res)
                      if (index != -1) {
                        res.splice(index)
                      } else {
                        res.push(paths)
                      }
                      me.cache = res
                      // console.log(res)
                      // console.log('/////////////////////////////')
                      // console.log(paths)
                      // console.log(me.cache)
                      me.$store.commit('getRouteTags', me.cache)
                      // console.log(me.$store.state.routeTags)
                      me.actionIndex = obj.index
                      // console.log('跳转')
                      me.$router.push({
                        path: obj.path,
                        query: obj.query,
                      })
                      // if(index!=-1){
                      //   me.cache.push(paths)
                      //   me.$store.commit('getRouteTags', me.cache)
                      //   console.log(me.cache)
                      // }
                    } else {
                      // console.log(567)
                      me.$router.push({
                        path: me.routePath + '/' + currentFrom.path.trim(),
                        query: query,
                      })
                    }
                    me.$router.push({
                      path: me.routePath + '/' + currentFrom.path.trim(),
                      query: query,
                    })
                    let rt = me.$router.routeTags
                    if (me.$route.query.row) {
                      let rowData = JSON.parse(me.$route.query.row)
                      let tagName = rowData.ZBH
                      let Id = rowData.Id
                      for (let r = 0; r < rt.length; r++) {
                        //每次切换标签时确保名字随之修改
                        if (rt[r].row && rt[r].row == me.$route.query.row) {
                          rt[r].meta.title = tagName
                          rt[r].meta.Id = Id
                        }
                      }
                    }
                  }

                  //me.actionCompnentName = me.formList[0].componentName
                }
              }
              if (response.data.Data && response.data.Data.StepButtons) {
                me.btnList = []
                for (let i = 0; i < response.data.Data.StepButtons.length; i++) {
                  const btn = response.data.Data.StepButtons[i]
                  me.btnList.push({
                    Id: i.toString(),
                    label: btn.Title,
                    icon: btn.Ico,
                    functionName: btn.Script,
                  })
                }
              }
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    async taskSend() {
      const me = this
      await this.$ajax
          .post(
              this.apiUrl +
              '/api/WorkFlowExecute/TaskSend?taskId=' +
              this.taskId +
              '&targetStepId=' +
              this.form.xyhj +
              '&targetUserId=' +
              this.form.uid +
              '&comment=' +
              this.form.clyj.trim()
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              ElMessage({
                type: 'info',
                message: '发送成功！',
              })
              for (let i = 0; i < me.$router.routeTags.length; i++) {
                if (me.$route.query.instanceId === me.$router.routeTags[i].meta.Id) {
                  me.$router.handleClose(me.$router.routeTags[i])
                }
              }
              window.sessionStorage.clear()
              me.$router.push('/buslist')
              me.$store.dispatch('getUserInfo');
            } else {
              if (response.data.ErrorMessage == null) {
                ElMessage({
                  type: 'error',
                  message: response.data.Data,
                })
              } else {
                ElMessage({
                  type: 'error',
                  message: response.data.ErrorMessage,
                })
              }

            }
          })
          .catch(function (error) {
            console.log(error)
            ElMessage({
              type: 'error',
              message: error,
            })
          })
    },
    async taskBack() {
      const me = this
      if (me.form.clyj.trim() == '') {
        ElMessage({
          type: 'info',
          message: '请填写处理意见',
        })
      } else {
        await this.$ajax
            .post(
                this.apiUrl +
                '/api/WorkFlowExecute/TaskBack?taskId=' +
                this.taskId +
                '&comment=' +
                this.form.clyj +
                '&backUserId=' +
                this.form.uid +
                '&backStepId=' +
                this.form.xyhj
            )
            .then(function (response) {
              if (response.data.IsSuccess) {
                ElMessage({
                  type: 'info',
                  message: '退回成功！',
                })
                for (let i = 0; i < me.$router.routeTags.length; i++) {
                  if (me.$route.query.instanceId === me.$router.routeTags[i].meta.Id) {
                    me.$router.handleClose(me.$router.routeTags[i])
                  }
                }
                let storage = window.sessionStorage;
                storage.clear()
                me.$router.push('/buslist')
                console.log('respomse:', response)
              } else {
                ElMessage({
                  type: 'error',
                  message: response.data.ErrorMessage,
                })
              }
            })
            .catch(function (error) {
              console.log(error)
              ElMessage({
                type: 'error',
                message: error,
              })
            })
      }
    },
    async TaskDelayRemark() {
      const me = this
      if (me.DelayRemark.trim() == '') {
        ElMessage({
          type: 'info',
          message: '请填写缓办原因',
        })
      } else {
        await this.$ajax
            .post(me.$appConfig.apiUrls.ywxtApi + '/api/YW/TaskDelay?InstanceId=' + me.instanceId + '&TaskId=' + me.taskId + '&DelayRemark=' + me.DelayRemark)
            .then(function (response) {
              if (response.data.IsSuccess) {
                me.DelaydialogVisible = false
                me.pendingTask()
              } else {
                //alert(response.data.ErrorMessage)
                ElMessage({
                  type: 'info',
                  message: '添加缓办失败：' + response.data.ErrorMessage,
                })
              }
            })
      }

    },
    async TaskCancelRemark() {
      const me = this
      if (me.CancelRemark.trim() == '') {
        ElMessage({
          type: 'info',
          message: '请填写作废原因',
        })
      // } else if (me.CancelRemark.trim().length < 10) {
      //   ElMessage({
      //     type: 'info',
      //     message: '作废原因需10字以上',
      //   })
      } else {
        await this.$ajax
            .post(me.$appConfig.apiUrls.ywxtApi + '/api/YW/TaskCancel?InstanceId=' + me.instanceId + '&CancelRemark=' + me.CancelRemark)
            .then(function (response) {
              if (response.data.IsSuccess) {
                me.CanceldialogVisible = false
                me.cancelTask()
              } else {
                //alert(response.data.ErrorMessage)
                ElMessage({
                  type: 'info',
                  message: '作废失败：' + response.data.ErrorMessage,
                })
              }
            })
      }

    },
    async DelTaskDelay() {
      const me = this
      await this.$ajax
          .get(me.$appConfig.apiUrls.ywxtApi + '/api/YW/DelTaskDelay?InstanceId=' + me.instanceId + '&TaskId=' + me.taskId)
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.solutionPendTask()
            } else {
              ElMessage({
                type: 'info',
                message: '解除缓办失败：' + response.data.ErrorMessage,
              })
            }
          })
    },
    //缓办
    async pendingTask() {
      const me = this
      await this.$ajax
          .post(
              this.apiUrl + '/api/WorkFlowExecute/PendingTask?taskId=' + this.taskId
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              ElMessage({
                type: 'info',
                message: '设置缓办成功！',
              })
              for (let i = 0; i < me.$router.routeTags.length; i++) {
                if (me.$route.query.instanceId === me.$router.routeTags[i].meta.Id) {
                  me.$router.handleClose(me.$router.routeTags[i])
                }
              }
              me.$router.push('/buslist')
              console.log('respomse:', response)
            } else {
              ElMessage({
                type: 'error',
                message: response.data.ErrorMessage,
              })
            }
          })
          .catch(function (error) {
            console.log(error)
            ElMessage({
              type: 'error',
              message: error,
            })
          })
    },
    //作废
    async cancelTask() {
      const me = this
      await this.$ajax
          .post(
              this.apiUrl + '/api/WorkFlowExecute/CancelTask?taskId=' + this.taskId
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              ElMessage({
                type: 'info',
                message: '项目已作废！',
              })
              for (let i = 0; i < me.$router.routeTags.length; i++) {
                if (me.$route.query.instanceId === me.$router.routeTags[i].meta.Id) {
                  me.$router.handleClose(me.$router.routeTags[i])
                }
              }
              me.$router.push('/buslist')
              console.log('respomse:', response)
            } else {
              ElMessage({
                type: 'error',
                message: response.data.ErrorMessage,
              })
            }
          })
          .catch(function (error) {
            console.log(error)
            ElMessage({
              type: 'error',
              message: error,
            })
          })
    },
    //解除缓办
    async solutionPendTask() {
      const me = this
      await this.$ajax
          .post(
              this.apiUrl +
              '/api/WorkFlowExecute/SolutionPendTask?taskId=' +
              this.taskId
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              ElMessage({
                type: 'info',
                message: '已解除缓办！',
              })
              for (let i = 0; i < me.$router.routeTags.length; i++) {
                if (me.$route.query.instanceId === me.$router.routeTags[i].meta.Id) {
                  me.$router.handleClose(me.$router.routeTags[i])
                }
              }
              me.$router.push('/buslist')
              console.log('respomse:', response)
            } else {
              ElMessage({
                type: 'error',
                message: response.data.ErrorMessage,
              })
            }
          })
          .catch(function (error) {
            console.log(error)
            ElMessage({
              type: 'error',
              message: error,
            })
          })
    },
    async taskRedirect() {
      let me = this
      if (me.taskzjr == '' || me.taskzjr == null || me.taskzjr == undefined) {
        ElMessage({
          type: 'info',
          message: '请选择转交人',
        })
      } else {
        await this.$ajax.post(me.apiUrl + '/api/WorkFlowExecute/TaskRedirect?instanceId=' + me.instanceId + '&taskId=' + me.taskId + '&receiverIds=' + me.taskzjr)
            .then(function (response) {
              if (response.data.IsSuccess) {
                ElMessage({
                  type: 'success',
                  message: '转交成功！',
                })
                for (let i = 0; i < me.$router.routeTags.length; i++) {
                  if (me.$route.query.instanceId === me.$router.routeTags[i].meta.Id) {
                    me.$router.handleClose(me.$router.routeTags[i])
                  }
                }
                me.$router.push('/buslist')
              } else {
                ElMessage({
                  type: 'error',
                  message: response.data.ErrorMessage,
                })
              }
              me.taskRedirectdialogVisible = false
            })
      }
    },
    async getzjr() {
      let me = this
      me.taskzjroptions = []
      await this.$ajax.get(me.$appConfig.apiUrls.userApi + '/api/Organize/GetOrganizeTree?permissionId=' + me.thisstep)
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.taskzjroptions = []
              response.data.Data.forEach(function (val) {
                if (val.nodes !== null) {
                  val.nodes.forEach(function (v) {
                    me.taskzjroptions.push({value: v.id, label: v.text});
                  })
                }
              })
            } else {
              ElMessage({
                type: 'error',
                message: response.data.ErrorMessage,
              })
            }
          })
    },
    reflash() {
      if (this.formList.length > 0) {
        this.actionIndex = this.formList[0].Id
      }
      this.loadForms()
    },
    getInput(value, id) {
      // console.log(value,id)
      this.form.zdry = value
      this.form.uid = id
    },
  },
  activated() {
    this.width = 250;
    console.log(this.$route.params)
    if (this.$route.query.row) {
      this.routePath = this.$route.path
      this.actionIndex = ''
      const rowData = JSON.parse(this.$route.query.row)
      if (this.fid != rowData.SBLX) {
        this.fid = rowData.SBLX
      }
      if (this.instanceId !== rowData.Id) {
        this.instanceId = rowData.Id
      }
      if (this.taskId !== rowData.taskid) {
        this.taskId = rowData.taskid
      } else {
        this.taskId = rowData.taskid
      }

      this.isReadOnly = rowData.isReadOnly ? true : false
      if (!this.isReadOnly) {
        if (rowData.Status) {
          this.isPending = rowData.Status.toString() === '6'
        } else {
          this.isPending = false
        }
      }
      this.loadForms()
      //his.$refs.fileList.getTreedataJson(this.instanceId, this.fid)
    }
    if (this.taskId && !this.isPending) {
      this.$ajax
          .post(
              this.apiUrl +
              '/api/WorkFlowTask/OpenTask?id=' +
              this.taskId
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              console.log('接手成功')
            } else {
              ElMessage({
                type: 'error',
                message: response.data.ErrorMessage,
              })
            }
          })
          .catch(function (error) {
            console.log(error)
            ElMessage({
              type: 'error',
              message: error,
            })
          })
    }
  },
  components: {
    Briefcase,
    // InfoList,
    fileList,
    InputTree,
    XHandle,
  },
}
</script>

<style scoped>
/* 、、、 */
.wp > span {
  font-size: 18px;
  padding-left: 5px;
  line-height: 40px;
}

.wrap-mp {
  /* border: 1px solid; */
  background: #ffffff;
  /* height: 400px; */
  margin-bottom: 20px;
  border-radius: 3px;
}

.wrap-mp .el-menu {
  border-right: 0px;
}

.wrap-mp .el-menu-item.is-active {
  border-left: 3px solid #3c8dbc;
  font-weight: 800;
  color: #000000;
}

.wrap-info .wrap-mp ul li:last-of-type {
  border-bottom: 1px solid #f4f4f4;
}

.wrap-info {
  /* border:1px solid red; */
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  height: 100%;
}

.wrap-info .el-header {
  --el-header-padding: 0 0;
  --el-header-height: 45px;
}

.wrap-info .el-main {
  --el-main-padding: 0 0 0 0;
}

.wrap-info .el-button-group {
  float: left;
}

.wrap-rv {
  height: 100%;
}

</style>
