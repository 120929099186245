<template>
  <el-input v-model="input"
             :readonly="isReadonly"
             :disabled="disabled"
            @change="pushInput">
    <template #append>
      <el-popover placement="bottom"
                  v-model:visible="visible"
                  :width="300"
                  :disabled="disabled"
                  class="tree-pop">
        <template #reference>
          <el-button @click="visible = !visible">选择</el-button>
        </template>
        <el-scrollbar>
          <el-tree class="ORGTree"
                   show-checkbox
                   v-loading="isLoading"
                   :default-expand-all="isExpand"
                   :check-on-click-node="true"
                   :data="data"
                   ref="tree"
                   highlight-current
                   check-strictly
                   node-key="id"
                   @check="handleCheck"
                   :props="defaultProps">
          </el-tree>
        </el-scrollbar>
      </el-popover>
    </template>
  </el-input>

</template>

<script>
let id = 1001
console.log(id)
export default {
  props: {
    isExpand: {
      type: Boolean,
      default: true,
    },
    bm: {
      type: String,
    },
    stepId: {
      type: String,
      default: '',
    },
    disabled:{
      type: Boolean,
    },
    isReadonly:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      input: this.bm,
      userId: '',
      organCodes: [],
      selectType: true, //设置单选或复选
      defaultProps: {
        children: 'nodes',
        label: 'text',
      },
      data: [],
      isLoading: true,
      visible: false,
    }
  },
  watch: {
    bm: {
      deep: true,
      handler(nv) {
        this.input = nv
      },
    },
    stepId:{
        deep:true,
        handler(){
            this.getOrganizeTree(this.stepId)
        }
    }
  },
  emits: ['getInput'],
  mounted() {
    if(this.stepId != '0'){
      this.getOrganizeTree(this.stepId)
    }
  },
  methods: {
    async getOrganizeTree(stepid) {
      let me = this
      me.data = [];
      me.isLoading = true;
      me.$refs.tree.setCheckedKeys([])
    //   console.log(this.stepId)
      await this.$ajax
        .get(
          this.$appConfig.apiUrls.userApi +
            '/api/Organize/GetOrganizeTree?parentId=' +
            '&permissionId='+stepid
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            let res = response.data.Data
            for (let i = 0; i < res.length; i++) {
              res[i].root = true
             if(stepid === 'bm'){
              res[i].root = false
              res[i].nodes = null
             }else if(res[i].nodes === null){
              res.splice(i, 1);
              i--;
             }
            }
            me.data = res
            me.isLoading = false
          } else {
            console.log('获取数据失败：', response)
            me.isLoading = false
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    handleCheck(a, b) {
      if (this.selectType && b.checkedKeys.length > 0) {
        this.$refs.tree.setCheckedKeys([a.id])
      }
      if(a.nodes!=null && this.stepId!='bm'){
        return;
      }else{
        this.currentchange();
      }
    },
    pushInput() {
      if(this.stepId==='bm'){
        this.$emit('getInput', this.input);
      }else{
        this.$emit('getInput', this.input, this.userId);
      }
    },
    //树型点击
    currentchange() {
      this.visible = false
      let treeData = this.$refs.tree.getCheckedNodes()
      let treeArray = []
      let idArray = []
      for (let key in treeData) {
        if (!treeData[key].root) {
          treeArray.push(treeData[key].text)
          idArray.push(treeData[key].id)
        }
      }
      // console.log(treeArray);
      this.input = treeArray.toString()
      //console.log( nodeArray)
      this.userId = idArray.toString()
      this.pushInput()
      // console.log('输入内容:', this.input);
    },
  },
}
</script>
<style scoped>
.el-tree {
  max-height: 300px;
}
</style>